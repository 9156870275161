import { defineStore } from 'pinia';
import profileService from '@/shared/services/profileService/profileService';
import {
  AdminProfile,
  LockedOutResponse,
  PartialAdminProfile,
} from '@/shared/types/profile';

interface State {
  userProfile?: AdminProfile;
  userLockedOutInfo: LockedOutResponse;
}

export default defineStore({
  id: 'profile',

  state: (): State => ({
    userProfile: undefined,
    userLockedOutInfo: {} as LockedOutResponse,
  }),

  actions: {
    resetStore() {
      this.userProfile = undefined;
    },

    async loadProfile() {
      try {
        this.userProfile = await profileService.getProfile();
      } catch (e) {
        this.userProfile = undefined;
      }
    },

    async updateProfile(profile: PartialAdminProfile) {
      await profileService.patchProfile(profile);

      await this.loadProfile();
    },

    async loadUserLockedOutInfo(email: string): Promise<void> {
      try {
        this.userLockedOutInfo = await profileService.getUserLockedOutInfo(
          email,
        );
      } catch (e) {
        this.userLockedOutInfo = {} as LockedOutResponse;
      }
    },
  },
});
