import { defineStore } from 'pinia';
import { LanguageCode } from '@/shared/types/language';
import languageService from '@/shared/services/languageService/languageService';
import { computed, ref } from 'vue';

export default defineStore('language', () => {
  const availableLanguages = ref<LanguageCode[]>([]);

  // TODO: Remove after all languages (JSONs) are added to the admin app
  const adminLanguages = computed<LanguageCode[]>(() =>
    availableLanguages.value.filter(
      (language: LanguageCode) =>
        language.code === 'en-US' || language.code === 'pt-PT',
    ),
  );

  function $reset(): void {
    availableLanguages.value = [];
  }

  async function loadLanguagesCodes(): Promise<void> {
    try {
      availableLanguages.value = (
        await languageService.getLanguagesCodes()
      ).results.languages;
    } catch (e) {
      availableLanguages.value = [];
    }
  }

  return {
    availableLanguages,
    adminLanguages,
    loadLanguagesCodes,
    $reset,
  };
});
