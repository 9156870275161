import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import { JSONFile, TranslationsList } from '@/shared/types/translations';

export default {
  getTranslations(): Promise<ApiResponse<TranslationsList>> {
    return axios.get('/translations/all');
  },
  updateTranslations(
    jsonContent: JSONFile | string,
    platform: string,
    lang: string,
  ): Promise<void> {
    return axios.patch(`/translations/${platform}/${lang}`, jsonContent);
  },
};
