import featureService from '@/shared/services/featureService/featureService';
import { Feature } from '@/shared/types/feature';
import { defineStore } from 'pinia';

interface State {
  features: Feature[];
}

export default defineStore({
  id: 'feature',

  state: (): State => ({
    features: [],
  }),

  actions: {
    async loadAllFeatures(): Promise<void> {
      try {
        this.features = (
          await featureService.getAllFeatures()
        ).results.features;
      } catch {
        this.features = [];
      }
    },

    updateFeaturesStatus(
      organizationName: string,
      features: Feature[],
    ): Promise<void> {
      return featureService.updateFeaturesStatus(organizationName, features);
    },

    async getFeaturesByOrganizationReference(
      organizationReference: string,
    ): Promise<Feature[]> {
      return (
        await featureService.getFeaturesByOrganizationReference(
          organizationReference,
        )
      ).results.features;
    },

    getOrganizationFeaturesByStatus(featuresByOrg: Feature[]): Feature[][] {
      const getActiveFeatures = featuresByOrg.filter(
        (feature) => feature.status === true,
      );
      const getInactiveFeatures = featuresByOrg.filter(
        (feature) => feature.status === false,
      );
      return [getActiveFeatures, getInactiveFeatures];
    },
  },
});
