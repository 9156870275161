import { defineStore } from 'pinia';
import {
  HttpStatus,
  LockErrorMessage,
  OTPLockedResponse,
} from '@/shared/types/generic';

interface ErrorState {
  errorCode?: HttpStatus;
  lockedErrorMessage?: LockErrorMessage;
}

export default defineStore({
  id: 'error',

  state: (): ErrorState => ({
    errorCode: undefined,
    lockedErrorMessage: undefined,
  }),

  actions: {
    setErrorCode(errorCode?: HttpStatus) {
      this.errorCode = errorCode;
    },

    setLockErrorMessage(errorMessage: OTPLockedResponse): void {
      this.lockedErrorMessage = {
        lockedOutEndTime: errorMessage.lockedOutEndTime,
        code: errorMessage.code,
        userTimezone: errorMessage.userTimezone,
      };
    },
  },
});
