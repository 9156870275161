import { ApiResponse } from '@/shared/types/generic';
import axios from '@/plugins/http/axios';
import { Admin, NewAdmin, EditAdmin } from '@/shared/types/admins';

export default {
  getAdmins(): Promise<ApiResponse<{ admins: Admin[] }>> {
    return axios.get('/users/admin');
  },

  createAdmin(newAdmin: NewAdmin): Promise<void> {
    return axios.post('/users/admin', newAdmin);
  },

  editAdmin(newAdmin: EditAdmin, reference: string): Promise<void> {
    return axios.put(`/users/admin/${reference}`, newAdmin);
  },

  deleteAdmin(reference: string): Promise<void> {
    return axios.delete(`/users/admin/${reference}`);
  },
};
