export enum Platform {
  MANAGER = 'MANAGER',
  OPERATOR = 'OPERATOR',
}

export interface Option {
  name: string | number | boolean | Array<unknown> | Record<string, unknown>;
}

export interface JSONFile {
  [key: string]: unknown;
}

export interface Translation {
  languageCode: string;
  translation: JSONFile;
  type: Platform;
}

export interface TranslationsList {
  translations: Translation[];
}

export interface PlatformFile {
  [key: string]: JSONFile;
}
