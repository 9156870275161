import { defineStore } from 'pinia';

export default defineStore({
  id: 'shared',

  state: () => ({
    notify: {} as () => void,
  }),

  actions: {
    saveNotify(notification: () => void): void {
      this.notify = notification;
    },
  },
});
