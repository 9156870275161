import { defineStore } from 'pinia';
import { CurrencyCode } from '@/shared/types/currency';
import currencyService from '@/shared/services/currencyService/currencyService';

export default defineStore({
  id: 'currency',

  state: () => ({
    currenciesCodes: [] as CurrencyCode[],
  }),

  actions: {
    resetStore() {
      this.currenciesCodes = [];
    },

    async loadCurrenciesCodes() {
      try {
        this.currenciesCodes = (
          await currencyService.getCurrenciesCodes()
        ).results.currencies;
      } catch (e) {
        this.currenciesCodes = [];
      }
    },
  },
});
