import translationsService from '@/shared/services/translationsService/translationsService';
import {
  Translation,
  PlatformFile,
  Platform,
  JSONFile,
} from '@/shared/types/translations';
import { defineStore } from 'pinia';

export default defineStore({
  id: 'translations',

  state: () => ({
    translations: [] as Translation[],
    manager: {} as PlatformFile,
    operator: {} as PlatformFile,
  }),

  actions: {
    async loadTranslations() {
      try {
        this.translations = (
          await translationsService.getTranslations()
        ).results.translations;

        this.translations
          .filter((translation) => translation.type === Platform.OPERATOR)
          .forEach((field) =>
            Object.assign(this.operator, {
              [field.languageCode]: field.translation,
            }),
          );

        this.translations
          .filter((translation) => translation.type === Platform.MANAGER)
          .forEach((field) =>
            Object.assign(this.manager, {
              [field.languageCode]: field.translation,
            }),
          );
      } catch (e) {
        this.manager = {} as PlatformFile;
        this.operator = {} as PlatformFile;
        throw new Error();
      }
    },

    async updateTranslations(
      jsonContent: JSONFile | string,
      platform: string,
      lang: string,
    ) {
      await translationsService.updateTranslations(jsonContent, platform, lang);
    },
  },
});
