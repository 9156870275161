import i18n from '@/plugins/i18n';
import { Locale } from '@/shared/types/generic';
import useAdminsStore from '@/store/admins/useAdminsStore';
import useCurrencyStore from '@/store/currency/useCurrencyStore';
import useErrorStore from '@/store/error/useErrorStore';
import useFeatureStore from '@/store/featureStore/useFeatureStore';
import useLanguageStore from '@/store/language/useLanguageStore';
import useOrganizationsStore from '@/store/organizations/useOrganizationsStore';
import useProfileStore from '@/store/profile/useProfileStore';
import useRolesStore from '@/store/roles/useRolesStore';
import useTokenStore from '@/store/token/useTokenStore';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import useUserStore from '@/store/user/useUserStore';
import { updateCommonsLocale } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { useRouter } from 'vue-router';

export default function useLogout() {
  const router = useRouter();

  function resetAllStores(): void {
    const adminsStore = useAdminsStore();

    const currencyStore = useCurrencyStore();

    const errorStore = useErrorStore();

    const featureStore = useFeatureStore();

    const languageStore = useLanguageStore();

    const organizations = useOrganizationsStore();

    const profileStore = useProfileStore();

    const tokenStore = useTokenStore();

    const translationsStore = useTranslationsStore();

    const userStore = useUserStore();

    const rolesStore = useRolesStore();

    adminsStore.$reset();

    currencyStore.$reset();

    errorStore.$reset();

    featureStore.$reset();

    languageStore.$reset();

    organizations.$reset();

    profileStore.$reset();

    tokenStore.$reset();

    translationsStore.$reset();

    userStore.$reset();

    rolesStore.$reset();
  }

  async function logOut(): Promise<void> {
    resetAllStores();

    localStorage.clear();

    i18n.global.locale.value = process.env.VUE_APP_I18N_LOCALE || Locale.US;

    updateCommonsLocale(process.env.VUE_APP_I18N_LOCALE || Locale.US);

    await router.push('/login');
  }

  return { resetAllStores, logOut };
}
