import { defineStore } from 'pinia';
import userService from '@/shared/services/userService/userService';
import { AxiosResponse } from 'axios';

interface State {
  userEmail: string;
  otp: string | undefined;
}

export default defineStore({
  id: 'user',

  state: (): State => ({
    userEmail: '',
    otp: undefined,
  }),

  actions: {
    resetStore() {
      this.userEmail = '';
      this.otp = undefined;
    },

    changePassword(password: string) {
      return userService.changePassword(password, this.userEmail, this.otp);
    },

    setUserEmail(email: string): void {
      this.userEmail = email;
    },

    recoverPassword(): Promise<AxiosResponse> {
      return userService.recoverPassword(this.userEmail);
    },

    verifyCode(otp: string): Promise<AxiosResponse> {
      this.otp = otp;
      return userService.verifyCode(this.userEmail, this.otp);
    },

    resendInvitation(users: string[]): Promise<AxiosResponse> {
      return userService.postResendInvitation(users);
    },

    async verifyPassword(password: string): Promise<AxiosResponse> {
      return userService.verifyPassword(password);
    },
  },
});
