<script setup lang="ts">
import {
  STRING_HAS_AT_LEAST_ONE_NUMBER_AND_SPECIAL_CHARACTER,
  STRING_HAS_AT_LEAST_ONE_UPPERCASE_AND_LOWERCASE_LETTER,
  STRING_HAS_BETWEEN_12_AND_64_CHARACTERS,
  STRING_HAS_NO_REPEATED_CONSECUTIVE_CHARACTERS,
} from '@/shared/constants/regularExpressions';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AppPasswordValidationsItem from '../AppPasswordValidationsItem/AppPasswordValidationsItem.vue';

interface Props {
  password: string;
}

const props = defineProps<Props>();

const passwordHasAtLeast12Characters = computed<boolean>(() =>
  STRING_HAS_BETWEEN_12_AND_64_CHARACTERS.test(props.password),
);

const passwordHasAtLeastOneUppercaseAndLowercaseLetter = computed<boolean>(() =>
  STRING_HAS_AT_LEAST_ONE_UPPERCASE_AND_LOWERCASE_LETTER.test(props.password),
);

const passwordHasAtLeastOneNumberAndSpecialCharacter = computed<boolean>(() =>
  STRING_HAS_AT_LEAST_ONE_NUMBER_AND_SPECIAL_CHARACTER.test(props.password),
);

const passwordHasNoRepeatedConsecutiveCharacters = computed<boolean>(() =>
  STRING_HAS_NO_REPEATED_CONSECUTIVE_CHARACTERS.test(props.password),
);

const { t } = useI18n();
</script>

<template>
  <div class="app-password-validations">
    <b>
      <span>{{ t('changePassword.mustContain') }}</span>
    </b>
    <AppPasswordValidationsItem
      :label="t('changePassword.validations.numberOfCharacters')"
      :is-valid="passwordHasAtLeast12Characters"
    />
    <AppPasswordValidationsItem
      :label="t('changePassword.validations.lettersCombination')"
      :is-valid="passwordHasAtLeastOneUppercaseAndLowercaseLetter"
    />
    <AppPasswordValidationsItem
      :label="t('changePassword.validations.specialCharacters')"
      :is-valid="passwordHasAtLeastOneNumberAndSpecialCharacter"
    />
    <AppPasswordValidationsItem
      :label="t('changePassword.validations.consecutiveCharacters')"
      :is-valid="passwordHasNoRepeatedConsecutiveCharacters"
    />
  </div>
</template>

<style scoped lang="scss">
$box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);

.app-password-validations {
  gap: 12px;
  padding: 16px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  box-shadow: $box-shadow;
  background-color: $white;
  border: 1px solid $gray-100;
}
</style>
