import axios from '@/plugins/http/axios';
import { AxiosResponse } from 'axios';

export default {
  changePassword(
    password: string,
    email: string,
    otp?: string,
  ): Promise<AxiosResponse> {
    const body = otp
      ? {
          email,
          password: window.btoa(password),
          otp,
        }
      : {
          email,
          password: window.btoa(password),
        };

    return axios.post('/users/change-password', body);
  },

  recoverPassword(email: string): Promise<AxiosResponse> {
    return axios.post('/users/recover-password', { email });
  },

  verifyCode(email: string, code: string): Promise<AxiosResponse> {
    return axios.post('/users/verify-otp', { email, otp: code });
  },

  postResendInvitation(userReferences: string[]): Promise<AxiosResponse> {
    return axios.post('/users/resend-invitation', { userReferences });
  },

  verifyPassword(password: string): Promise<AxiosResponse> {
    return axios.post('/auth/verify-password', {
      password: window.btoa(password),
    });
  },
};
