import { defineStore } from 'pinia';
import { Admin, NewAdmin, EditAdmin } from '@/shared/types/admins';
import adminsService from '@/shared/services/adminsService/adminsService';

export default defineStore({
  id: 'admins',

  state: () => ({
    admins: [] as Admin[],
  }),

  actions: {
    resetStore() {
      this.admins = [];
    },

    async loadAdmins() {
      try {
        this.admins = (await adminsService.getAdmins()).results.admins;
      } catch (e) {
        this.admins = [];
      }
    },

    createAdmin(newAdmin: NewAdmin) {
      return adminsService.createAdmin(newAdmin);
    },

    async editAdmin(newAdmin: EditAdmin, reference: string) {
      await adminsService.editAdmin(newAdmin, reference);

      await this.loadAdmins();
    },

    async deleteAdmin(reference: string) {
      await adminsService.deleteAdmin(reference);
    },
  },
});
