import axios from '@/plugins/http/axios';
import {
  AdminProfile,
  LockedOutResponse,
  PartialAdminProfile,
} from '@/shared/types/profile';

export default {
  getProfile(): Promise<AdminProfile> {
    return axios.get('/profiles/admin');
  },

  patchProfile(profile: PartialAdminProfile): Promise<AdminProfile> {
    return axios.patch('/profiles/admin', profile);
  },

  getUserLockedOutInfo(email: string): Promise<LockedOutResponse> {
    return axios.get(`auth/locked-out/${email}`);
  },
};
